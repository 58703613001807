<template>
	<div class="portfolio-page">
		<div class="inner-page">
			<div class="content-container fixed-width">
				<div class="indent">
					<h1><span id="title1" class="txtan">Selected </span><span id="title2" class="txtan light">Projects & Work</span></h1>
					<p class="txtan" id="bread">Below is a selection of cases. Please contact me for examples of specific work, solutions, or customer testimonials.</p>
					<p class="txtan" id="clients">Noteable clients include:</p>
					<div class="notable-clients fadein" style="animation-delay: 1400ms; animation-duration: 3000ms">
						<ul>
							<li><img src="../assets/client-logos/bet365-logo.svg" width="80" height="30" alt="logo for Bet365" class="client-logo"></li>
							<li><img src="../assets/client-logos/carlsberg-logo.svg" width="77" height="30" alt="logo for Carlsberg" class="client-logo"></li>
							<li><img src="../assets/client-logos/Microsoft-logo.svg" width="80" height="30" alt="logo for Microsoft" class="client-logo"></li>
							<li><img src="../assets/client-logos/dla-piper-logo.svg" width="51" height="30" alt="logo for DLA Piper" class="client-logo"></li>
							<li><img src="../assets/client-logos/coca-cola-logo.svg" width="80" height="30" alt="logo for Coca Cola" class="client-logo"></li>
							<li><img src="../assets/client-logos/klm-logo.svg" width="51" height="30" alt="logo for KLM" class="client-logo"></li>
							<li><img src="../assets/client-logos/virgin-logo.svg" width="34" height="30" alt="logo for Virgin Media" class="client-logo"></li>
							<li><img src="../assets/client-logos/leovegas-logo.svg" width="80" height="30" alt="logo for LeoVegas" class="client-logo"></li>
							<li><img src="../assets/client-logos/novo-nordisk-logo.svg" width="42" height="30" alt="logo for Novo Nordisk" class="client-logo"></li>
							<li><img src="../assets/client-logos/uddannelses-og-forskningsministeriet-logo.svg" width="80" height="30" alt="logo for Uddannelses og Forskningsministeriet" class="client-logo"></li>
							<li><img src="../assets/client-logos/betfair-logo.svg" width="80" height="30" alt="logo for Betfair" class="client-logo"></li>
							<li><img src="../assets/client-logos/chiquita-vector-logo.svg" width="25" height="30" alt="logo for Chiquita" class="client-logo"></li>
							<li><img src="../assets/client-logos/kobenhavns-kommune-logo.svg" width="49" height="30" alt="logo for Københavns Kommune" class="client-logo"></li>
							<li><img src="../assets/client-logos/paddy-power-logo.svg" width="80" height="30" alt="logo for Paddy Power" class="client-logo"></li>
						</ul>
					</div>
					
				</div>
				<PortfolioView></PortfolioView>
			</div>
		</div>
		<div class="page-background" id="background-video">
			<video class="show fadeInAnimation" autoplay playsinline muted loop @loadeddata="videoLoaded()" poster="../assets/videos/bg4.jpg">
				<source src="../assets/videos/bg4.webm" type="video/webm">
				<source src="../assets/videos/bg4.mp4" type="video/mp4">
			</video>
		</div>
	</div>
</template>

<script>


	import PortfolioView from "../components/portfolio/portfolioView.vue";

	export default {
		name: 'Portfolio',
		components: {
			PortfolioView
		},
		data() {
			return {
				videoReady: false,
			};
		},
		mounted(){
			this.textAnimation()
		},
		methods: {
			videoLoaded() {
				this.videoReady = true;
			},
			textAnimation() {
				

				var timeline = [
					{
						id: 'title1',
						start: 300,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'title2',
						start: 450,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'bread',
						start: 750,
						style: 'word',
						class: 'fadein',
						segementAnimationDelay: 8,
						segementAnimationDuration: 3000,
					},
					{
						id: 'clients',
						start: 1200,
						style: 'word',
						class: 'fadein',
						segementAnimationDelay: 8,
						segementAnimationDuration: 3000,
					},
				];
				
				
				timeline.forEach(function(item) {


					if (item.style == 'character') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/.{1}/g);
						

						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == ' ') ? '&nbsp' : letters[i];

							html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span>'
						}

						elem.innerHTML = html;
					}

					if (item.style == 'word') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/\S*/g);

						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == '') ? '' : letters[i];

							if (text != ''){
								html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '&nbsp;</span>'
							}
							
						}

						elem.innerHTML = html;
					}

					if (item.style == 'all') {

						let txtanElems = document.getElementById(item.id);
						let text = txtanElems.textContent;

						const startDelay = item.start;
						const letterDelay = item.segementAnimationDelay;
						const animationDuration = item.segementAnimationDuration;

						const delay = startDelay + letterDelay;

						txtanElems.innerHTML = '<span class="letter ' + item.class + '" style="animation-delay:' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span> '; 
					}
					

				});
			}
		}
	}
  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

	@import "../scss/abstracts/_variables.scss";

	#app .content .content-container {
	
		p#bread {
			margin-bottom:0px;
		}

		p#clients {
			margin-bottom:0px;
			font-weight: bold;
		}


	} 

	.portfolio-page .notable-clients {


		opacity: 0;		
		-webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
		mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
		padding: 12px; 
		margin-top: 10px;
		margin-bottom:50px;
		background: $gray-700;

		ul {
			list-style: none;
			margin:0px;
			padding:0px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-content: center;
			align-items: center;
			padding-left: 10px;
			overflow-x: scroll;
			-ms-overflow-style: none;  /* Internet Explorer 10+ */
			scrollbar-width: none;  /* Firefox */

			&::-webkit-scrollbar { 
				display: none;  /* Safari and Chrome */
			}

			li {
				margin-right: 16px;
				img {
					max-height: 30px;
					height: 30px;
					max-width: 80px;
					filter: invert(100%);
					display: block;
				}
			}

		}

	}

</style>