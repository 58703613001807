<template>	
	<div class="portfolio-item" :style="'animation-delay: ' + delay + 'ms;'">
		<router-link :to="permalink" class="inside">
		<img class="thumbnail loading" @load="checkImageLoadStatus($event)" :id="id+'-thumbnail'" :src="thumbnail" :alt="title">
		<img class="thumbnail-preview show" :id="id+'-thumbnail-preview'" :src="thumbnailPreview" :alt="'Thumbnail for ' + title">
		<div class="text">
			<h3>{{title}}</h3>
			<span>{{description}}</span>
		</div>
		<div class="dot-overtaking show"></div>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'PortfolioItem',
	props: {
		permalink: {
			type: String,
			default: ''
		},
		id: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: 'Untitled'
		},
		description: {
			type: String,
			default: 'No description...'
		},
		thumbnail: {
			type: String,
			default: '/assets/images/default-portfolio-item-thumbnail.jpg'
		},
		thumbnailPreview: {
			type: String,
			default: '/assets/images/default-portfolio-item-thumbnail.jpg'
		},
		delay: {
			type: Number,
			default: 0
		},
		type: {
			type: String,
			default: ''
		},
		tags: {
			type: Array,
			default: function () {
				return [
					{
						tag: 'All',
						link: 'all'
					}
				]
			}
		},
	},
	methods: {
		checkImageLoadStatus(event) {

			const id = event.target.id;
			const thumbnail = document.querySelector('#' + id);
			const thumbnailPreview = document.querySelector('#' + id + '-preview');
		
			thumbnail.classList.remove('loading');
			thumbnailPreview.classList.remove('show');
			thumbnailPreview.classList.add('hide');

			const loader = document.querySelector('#' + id + ' ~ .dot-overtaking'); 
			loader.classList.remove('show');

		},
	}
}
</script>

<style>

	.portfolio-container .portfolio-item {
		flex: 1 0 50%; 
		margin: 0px;
		padding-top: calc(100% / 3);
		background-color: rgb(15,15,15);
		position:relative;
		overflow:hidden;
		animation-fill-mode: both;
		animation-name: cardFlipIn;
		animation-duration: 1s;
		animation-timing-function: cubic-bezier(0,.37,.02,1.01); 
		cursor:pointer;
	}

	.portfolio-container .portfolio-item .inside{
		height: 100%;
		width: 100%;
		cursor:pointer;
		position:absolute;
		top:0px;
		left:0px;
		background:rgb(30, 30, 30);
	}

	.portfolio-container .portfolio-item .inside img{
		width: 100%;
		height: 100%;
		position:absolute;
		top:0px;
		left:0px;
		object-fit: cover;
		transform: scale3D(1,1,1);
		opacity:1;
		filter: grayscale(100%);
		transition-property: transform, opacity, filter;
		transition-duration: 1s;
		transition-timing-function: cubic-bezier(0.25, 0, 0.4, 1);
		pointer-events: none;
		-webkitpointer-events: none;
		-moz-pointer-events: none;
		-o-pointer-events: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	.portfolio-container .portfolio-item .inside img.thumbnail.loading{
		opacity:0;
	}

	.portfolio-container .portfolio-item .inside img.thumbnail-preview {
		filter:blur(5px) grayscale(100%);
		width: calc(100% + 20px);
		height: calc(100% + 20px);
		margin: -10px -10px -10px -10px;
		opacity:0;
	}

	.portfolio-container .portfolio-item .inside img.thumbnail-preview.show{
		opacity:0.5;
		transition-duration: 1s;
	}

	.portfolio-container .portfolio-item .inside img.thumbnail-preview.hide{
		opacity:0;
		transition-duration: 1s;
	}


	.portfolio-container .portfolio-item .inside .text {
		width: 100%;
		height: 65%;
		position: absolute;
		bottom: 0px;
		left: 0px;
		color: #FFF;
		opacity: 0;
		transition-property: opacity, transform;
		transition-duration: 0.3s;
		transition-timing-function: cubic-bezier(0.25, 0, 0.4, 1);
		transform: translateY(10px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		padding-bottom: 30px;
		background: linear-gradient(0deg, hsla(0, 0%, 0%, 0.8) 0%, hsla(0, 0%, 0%, 0.738) 19%, hsla(0, 0%, 0%, 0.541) 34%, hsla(0, 0%, 0%, 0.382) 47%, hsla(0, 0%, 0%, 0.278) 56.5%, hsla(0, 0%, 0%, 0.194) 65%, hsla(0, 0%, 0%, 0.126) 73%, hsla(0, 0%, 0%, 0.075) 80.2%, hsla(0, 0%, 0%, 0.042) 86.1%, hsla(0, 0%, 0%, 0.021) 91%, hsla(0, 0%, 0%, 0.008) 95.2%, hsla(0, 0%, 0%, 0.002) 98.2%, hsla(0, 0%, 0%, 0) 100% );
		text-align: center;
	}

	.portfolio-container .portfolio-item:hover .inside .text {
		opacity: 1;
		transform: translateY(0px);
	}

	.portfolio-container .portfolio-item .inside .text h3 {
		margin: 0px;
		font-size: 1rem;
		letter-spacing: 1px;
		margin-bottom:-2px;
		text-shadow: 0px 5px 25px rgb(0, 0, 0);
	}

	.portfolio-container .portfolio-item .inside .text span {
		font-size:0.9rem;
	}

	.portfolio-container .portfolio-item:hover .inside img.thumbnail, .portfolio-container .portfolio-item:hover .inside img.thumbnail-preview  {
		transition-duration: 0.25s;
		transform: scale3D(1.05,1.05,1.05);
		opacity:0.85;
		filter: grayscale(0%);
	}

	.portfolio-container .portfolio-item:hover .inside img.thumbnail-preview.show  {
		opacity:0.4;
		filter: grayscale(0%) blur(5px);
	}

	.portfolio-container .portfolio-item:hover .inside img.thumbnail-preview.hide  {
		opacity:0;
	}

	.portfolio-container .dot-overtaking {
		margin-left: auto;
		margin-right: auto;
		top: 45%;
		transition-property: opacity;
		transition-duration: 0.3s;
		transition-delay: 0s;
		opacity: 0;
	}

	.portfolio-container .dot-overtaking.show {
		opacity: 0.25;
	}



</style>
