<template>
	<div class="portfolio-single-page">
		<div class="inner-page"  @scroll="pageScroll($event)">
			<div class="content-container fixed-width loading">
				<div class="indent" :class="data.attributes.slug">
					<h2>{{data.attributes.projectDataString}} • {{data.attributes.projectType}}</h2>
					<h1>{{data.attributes.title}}</h1>
					<div class="description">
						<div class="col-left">
							<h3 class="hr"><span>{{data.attributes.longDescriptionTitle}}</span></h3>
							<div class="description-content" v-html="data.attributes.longDescription"></div>
							<a v-for="(item, index) in dataButtons" :key="index" class="btn icon link inline" :target="item.target" :href="item.url">{{item.label}}</a>
						</div>
						<div class="col-right">
							
							<h3 class="hr"><span>Details</span></h3>
							
							<h4>Client</h4>
							<ul>
								<li>{{data.attributes.client}}</li>
							</ul>

							<h4>Project Date</h4>
							<ul>
								<li>{{data.attributes.projectDataString}}</li>
							</ul>

							<h4>Categories</h4>
							<ul>
								<li v-for="(item, index) in dataCategories" :key="index">{{item.attributes.Title}}</li>
							</ul>

						</div>
					</div>

					<div class="gallery">
						<h3 class="hr" v-if="Object.keys(dataGalleryItems).length > 0">Gallery</h3>
						<div class="container" id="gallery" v-if="galleryVisible"></div>
					</div>

					<div class="work" v-if="Object.keys(dataWorkInvolved).length > 0">
						<h3 class="hr">Work Involved</h3>
						<ul>
							<li v-for="(item, index) in dataWorkInvolved" :key="index">
								<div class="img" :style="backgroundImage(item.image.data)"></div>
								<div class="body">
									<h4>{{item.title}}</h4>
									<p v-html="item.description"></p>
								</div>
							</li>
						</ul>
					</div>
					

				</div>
			</div>
		</div>
		<div class="page-background" id="backgroundvideo">
			<video class="show fadeInAnimation" playsinline autoplay muted loop @loadeddata="videoLoaded()" :placeholder="backgroundVideoFallback">
			</video>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';

	export default {
		name: "SinglePortfolioItem",
		data() {
			return {
				data: {
					attributes: {
						title: "",
						description: "",
						longDescription: "",
						longDescriptionTitle: "",
						client: "-",
						projectDataString: "",
						projectType: "",
						slug: ""
					}
				},
				dataButtons: [],
				dataWorkInvolved: [],
				dataGalleryItems: [],
				dataCategories: [],
				backgroundVideo: '',
				backgroundVideoMime: '',
				backgroundVideoSecondary: '',
				backgroundVideoSecondaryMime: '',
				backgroundVideoFallback: '',
				galleryVisible: false,
				loading: true,
				videoReady: false,
				maxRows: 0
			};
		},
		mounted() {
			//this.textAnimation();
			
			this.$nextTick(function () {

				this.loading = true;
				this.$store.commit('setLoading', true);

				// Get porfolio item details
				axios.get(this.$store.state.app.apiUrl + '/projects?populate[0]=workInvolved.image&populate[1]=galleryItem.image&populate[2]=galleryItem.thumbnail&populate[3]=button&populate[4]=categories&populate[5]=backgroundVideo&populate[6]=backgroundVideoSecondary&populate[7]=backgroundVideoFallback&filters[slug][$eq]=' + this.$route.params.itemname).then(response => {
						this.data = response.data.data[0];
						this.dataWorkInvolved = response.data.data[0].attributes.workInvolved;
						this.dataGalleryItems = this.parseGalleryData(response.data.data[0].attributes.galleryItem);
						this.dataButtons = response.data.data[0].attributes.button;
						this.dataCategories = response.data.data[0].attributes.categories.data;
						this.backgroundVideo = (response.data.data[0].attributes.backgroundVideo.data) ? response.data.data[0].attributes.backgroundVideo.data.attributes.url : '';
						this.backgroundVideoMime = (response.data.data[0].attributes.backgroundVideo.data) ? response.data.data[0].attributes.backgroundVideo.data.attributes.mime : '';
						this.backgroundVideoSecondary = (response.data.data[0].attributes.backgroundVideoSecondary.data) ? response.data.data[0].attributes.backgroundVideoSecondary.data.attributes.url : '';
						this.backgroundVideoSecondaryMime = (response.data.data[0].attributes.backgroundVideoSecondary.data) ? response.data.data[0].attributes.backgroundVideoSecondary.data.attributes.mime : '';
						this.backgroundVideoFallback = (response.data.data[0].attributes.backgroundVideoFallback.data) ? this.$store.state.app.cdnUrl + response.data.data[0].attributes.backgroundVideoFallback.data.attributes.url : '';
						this.loadGallery();
						this.loadBackgroundVideo();
						this.loading = false;
						this.$store.commit('setLoading', false);

						// Set Page title
						this.setPageMeta(this.data.attributes.title);
						this.setPageMetaDescription(this.data.attributes.title, this.dataWorkInvolved);

						
						const contentContainer = document.getElementById('app').querySelector('.portfolio-single-page').querySelector('.inner-page').querySelector('.content-container');
						contentContainer.classList.remove('loading');
						
				});

			});
		},
		methods: {
			loadBackgroundVideo(){

				const video = document.getElementById('backgroundvideo').querySelector('video');
				
				// Primary Video
				const newSource = document.createElement('source');
				newSource.setAttribute('src', this.$store.state.app.cdnUrl + this.backgroundVideo);
				newSource.setAttribute('type', this.backgroundVideoMime);
				video.appendChild(newSource);

				// Secondary (Backup) Video
				if (this.backgroundVideoSecondary != ''){
					const newSourceSecondary = document.createElement('source');
					newSourceSecondary.setAttribute('src', this.$store.state.app.cdnUrl + this.backgroundVideoSecondary);
					newSourceSecondary.setAttribute('type', this.backgroundVideoSecondaryMime);
					video.appendChild(newSourceSecondary); 
				}


				video.play();
			},
			videoLoaded() {
				this.videoReady = true;
			},
			textAnimation() {

				var timeline = [
					{
						id: 'title1-portfolio-single',
						start: 300,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'title2-portfolio-single',
						start: 450,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'bread-portfolio-single',
						start: 750,
						style: 'word',
						class: 'fadein',
						segementAnimationDelay: 8,
						segementAnimationDuration: 3000,
					},
				];
				
				
				timeline.forEach(function(item) {


					if (item.style == 'character') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/.{1}/g);
						
						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == ' ') ? '&nbsp' : letters[i];

							html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span>'
						}

						elem.innerHTML = html;
					}

					if (item.style == 'word') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/\S*/g);

						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == '') ? '&nbsp' : letters[i];

							html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span>'
						}

						elem.innerHTML = html;
					}

					if (item.style == 'all') {

						let txtanElems = document.getElementById(item.id);
						let text = txtanElems.textContent;

						const startDelay = item.start;
						const letterDelay = item.segementAnimationDelay;
						const animationDuration = item.segementAnimationDuration;

						const delay = startDelay + letterDelay;

						txtanElems.innerHTML = '<span class="letter ' + item.class + '" style="animation-delay:' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span> '; 
					}
					

				});
			},
			backgroundImage(data){
				if (data){
					return "background-image:url('" + this.$store.state.app.cdnUrl +  data.attributes.url + "');"
				}
			},
			parseGalleryData(data){

				const galleryData = [];
				
				data.forEach(function(item){
					

						let itemCleaned = {};

					// --------------------------------
					// Determine content type
					// --------------------------------

						itemCleaned.contentType = 'image'
						itemCleaned.renderer = 'image'

						if (item.videoLink){
							if (item.videoLink.includes("youtube.com")){
								itemCleaned.contentType = 'video'
								itemCleaned.renderer = 'youtube'
							}
							if (item.videoLink.includes("vimeo.com")){
								itemCleaned.contentType = 'video'
								itemCleaned.renderer = 'vimeo'
							}
						} 
					
					// --------------------------------
					// Sanitiza data from API
					// --------------------------------

						itemCleaned.title = item.title;
						itemCleaned.description = item.description;
						itemCleaned.positionTop = item.positionTop;
						itemCleaned.positionLeft = item.positionLeft;
						itemCleaned.positionWidth = item.positionWidth;
						itemCleaned.positionHeight = item.positionHeight;
						itemCleaned.thumbnail = item.thumbnail.data.attributes.url;
						itemCleaned.image = item.image.data.attributes.url;
						itemCleaned.imageWidth = item.image.data.attributes.width;
						itemCleaned.imageHeight = item.image.data.attributes.height;
						itemCleaned.imageOrientiation = (item.image.data.attributes.height < item.image.data.attributes.width) ? 'width' : 'height';
						itemCleaned.videoLink = item.videoLink;

					// --------------------------------
					// Add to cleaned array
					// --------------------------------
		
						galleryData.push(itemCleaned);

				});

				return galleryData;


			},
			loadGallery(){
				
				// Gallery stuff
				const items = this.dataGalleryItems;

				if (items.length > 0){

					this.galleryVisible = true;

					this.$nextTick(function () {
						
						let galleryContainer = document.getElementById('gallery');
						let html = '';

						items.forEach(function(item, index){

							if (item.positionTop > this.maxRows) {
								this.maxRows = item.positionTop;
							}

							let icon = '';

							if (item.contentType == 'video'){
								let videoIconPath = require('../assets/icons/video.svg');
								icon = `<img class="icon" src="${videoIconPath}">`;
							}

							require.context('../assets/', false, /\.png$/)

							html += `<a 
								class="item" 
								data-media-type="${item.contentType}"
								data-media-renderer="${item.renderer}"
								data-media-index="${index}"
								href="#"
								style="
									grid-column-start: ${item.positionLeft}; 
									grid-row-start: ${item.positionTop}; 
									grid-column-end: span ${item.positionWidth};  
									grid-row-end: span ${item.positionHeight}; 
							"><div data-media-index="${index}" style="background-image:url('${this.$store.state.app.cdnUrl}${item.thumbnail}');"></div>${icon}</a>`;

						}.bind(this)); 


						galleryContainer.innerHTML = html;

						// Add event listener for clicks on items
						const newItems = galleryContainer.querySelectorAll('.item');
						newItems.forEach(function(item){
							item.addEventListener("click", this.lightboxOpen);
						}.bind(this));

						// Resize window event for gallery
						window.addEventListener("resize", this.resizeGallery);
					
						// Initial resize
						this.resizeGallery(); 

					})

				} else {
					this.galleryVisible = false;
				}
			},
			resizeGallery(){
				let galleryContainer = document.getElementById('gallery');
				if (galleryContainer != null) {
					const galleryContainerHeight = galleryContainer.offsetWidth * (0.25 * this.maxRows);
					galleryContainer.style.height = galleryContainerHeight + 'px';
				}
			},
			lightboxOpen(activeItem){


				const activeIntex = activeItem.target.getAttribute('data-media-index');

				const data = {
					active: activeIntex,
					items: this.dataGalleryItems
				}

				this.$store.commit('openLightbox', data);
			},
			pageScroll(event){
				
				const yScrollPos = event.target.scrollTop
				
				const yScrollStart = 50;
				const yScrollDistance = 500;

				const yScroll = (yScrollPos < yScrollStart) ? 0 : (yScrollPos - yScrollStart);
				const yScrollPct = (yScroll / yScrollDistance > 1) ? 1 : (yScroll / yScrollDistance).toFixed(2);
				
				if (this.pageScrollPos != 1 - yScrollPct){
					const opacity = (1 - yScrollPct).toFixed(2);
					this.pageScrollPos = opacity;
					const videoElem = document.getElementById('backgroundvideo');
					videoElem.style.opacity = opacity;
				}

			},
			setPageMeta(title){
				const pageTitle = (title) ? title + ' - Selected Work – Mickie C. Storm' : 'Mickie C. Storm';
				document.getElementsByTagName('title')[0].innerHTML = pageTitle;
			},
			setPageMetaDescription(title, workInvolved){
				const pageMetaDescription = (workInvolved[0].description) ? title + ' – ' + workInvolved[0].description : 'Details for case';
				const metaDescription = document.querySelector('meta[name="description"]');
				metaDescription.content = pageMetaDescription;
			},
			formatDate(date){
				const timestamp = Date.parse(date);
				var dateObject = new Date(timestamp);
				let year = dateObject.getFullYear();
				return (isNaN(year)) ? 'Present' : year;
			}
		}
	}

</script>
<style lang="scss">
	
	@import "../scss/abstracts/_variables.scss";

	#app .content .portfolio-single-page .content-container {

		opacity:1;
		transition-property: opacity;
		transition-delay: 0ms;
		transition-duration: 500ms;
		margin-top: 150px;

		&.loading {
			opacity:0;
			transition-property: opacity;
			transition-duration: 0ms;
		}


		h1 {
			font-size: $base-font-size * 4;
			letter-spacing: $base-font-size * 4 * $letterspacing-multiplier;
			margin:0px;
		}

		h2 {
			font-size: $font-size-large;
			letter-spacing: $font-size-large * $letterspacing-multiplier;
			font-weight: 300;
			margin:0px;
			margin-bottom:5px;
		}

		h3.hr {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: flex-start;
					align-content: stretch;
					align-items: center;
					font-size: $font-size-large;
					margin:0px;
					margin-bottom:20px;
					letter-spacing: $font-size-large * $letterspacing-multiplier;

					span {
						flex: 0 1 auto;
					}

					&::after {
						flex: 1 0 auto;
						background: $gray-600;
						height: 2px;
						margin-left: 20px;
						content: '';
					}
		}

		.description {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-content: stretch;
			align-items: stretch;
			margin-top:60px;

			.description-content {


				p {
					margin-bottom: 12px;
					opacity: 1;
					color: $gray-200;
				}

				h3 {
					font-family: $base-font-size;
					margin-top: 12px;
					margin-bottom: 6px;
				}

				a {
					color: #FFF !important;
					text-decoration: underline;
				}

			}


			div {
					

				h4 {
					font-size: $font-size-normal * 0.75;
					letter-spacing: $font-size-normal * $letterspacing-multiplier;
					margin:0px;
					font-weight: 400;
					color: $text-color;
				}

				ul {
					list-style: none;
					margin:0px;
					padding:0px;
					margin-top:3px;
					margin-bottom:20px;

					li {
						margin:0px;
						padding:0px;
						font-family: $font-header;
						font-size: $font-size-normal;
						text-transform: uppercase;
						letter-spacing: $font-size-normal * $letterspacing-multiplier;
						color: $gray-300;
					}
				}

				p {
					width: 100%;
					max-width: 100%;
					padding-right: $margin-l;
					margin:0px;
					margin-bottom:40px;
				}

			}

			.col-left {
				flex: 1 1 auto;
				margin-right:$margin-xl;
			}

			.col-right {
				flex: 1 0 auto;
				width: 250px;
				max-width: 250px;
				min-width: 250px;
			}

		}

		.work {
			margin-top:60px;
			ul {
				margin:0px;
				margin-top:30px;
				padding:0px;
				list-style: none;

				li {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: flex-start;
					align-content: stretch;
					align-items: stretch;
					border-bottom: 1px solid rgba(30,30,30,0.5);

					.img {
						flex: 0 0 auto;
						width: 300px;
						height: 200px;
						background: linear-gradient(319.92deg, #191919 0%, #202020 77.15%);
						background-size:cover;
					}

					.body {

						flex: 1 1 auto;

						display: flex;
						flex-direction: column;
						flex-wrap: nowrap;
						justify-content: center;
						align-content: flex-start;
						align-items: stretch;
						padding: $margin-s $margin-l;
						background: linear-gradient(319.92deg, #111111 0%, #181818 77.15%);

						h4 {
							margin:0px;
							margin-bottom:6px;
						}

						p {
							margin:0px;
							width: 100%;
						}


					}

				}

			}

		}

		.gallery {
			
			margin-top:80px;

			.container {
				
				margin-top:30px;
				display: grid; 
				grid-template-columns: 1fr 1fr 1fr; 
				gap: 0px 0px;
				width: 100%;
				gap: 20px 20px; 
				

				.item {
					
					overflow:hidden;
					position:relative;
					background: linear-gradient(319.92deg, #1B1B1B 0%, #202020 77.15%);
					background-size: cover;

					background-position: center center;

					div {
						background-size: cover;
						background-position: center center;
						width: 100%;
						height: 100%;
						transform: scale3D(1,1,1);
						opacity:1;
						transition-property: transform, opacity, filter;
						transition-duration: 1s;
						transition-timing-function: cubic-bezier(0.25, 0, 0.4, 1);
					}

					&:hover div {
						transition-duration: 0.25s;
						transform: scale3D(1.05,1.05,1.05);
						opacity:0.85;
					}

					.icon {
						position:absolute;
						top: 5px;
						right: 6px;
					}

				}

			}

		}

	}



</style>
