<template>

    <div class="portfolio-container grid">
      <PortfolioItem
        v-for="(item, index) in items"
        :key="index"
				:id="'portfolio-'+index"
        :title="item.attributes.title"
				:description="item.attributes.description"
				:permalink="'/work/' + item.attributes.slug"
        :delay="500 + (75 * index)"
				:thumbnail="this.$store.state.app.cdnUrl + item.attributes.thumbnail.data.attributes.url"
				:thumbnailPreview="this.$store.state.app.cdnUrl + item.attributes.thumbnail.data.attributes.formats.thumbnail.url"
      />
    </div>

</template>

<script>

	import PortfolioItem from "./portfolioItem.vue";
	import axios from 'axios';

	export default {
		name: "PortfolioView",
		components: {
			PortfolioItem
		},
		emits: [
			'loading'
		],
		data() {
			return {
				items: [],
			};
		},
		mounted() {
			this.$nextTick(function () {
				
				this.$store.commit('setLoading', true);

				// Get portfolio items
				axios
					.get(this.$store.state.app.apiUrl + '/projects/?populate[0]=thumbnail&populate[1]=categories')
					.then(response => {
						this.$store.commit('setLoading', false);
						this.items = response.data.data;
				});

			});
		}
	}

</script>

<style>
.portfolio-container {
  display: flex;
  flex-wrap: wrap;
}
</style>